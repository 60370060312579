import React from "react"
import ReactMarkdown from "react-markdown"

import SimpleLayout from "../layouts/SimpleLayout"
import SEO from "../components/seo"

import styles from "./styles/terms.module.css"

const TermsPageComponent = ({
  seoTitle,
  seoDescription,
  seoImage,
  text,
  url,
  features,
  isPreview,
  ...props
}) => (
  <SimpleLayout path={props.path} features={features} isPreview={isPreview}>
    <SEO
      title={seoTitle}
      description={seoDescription}
      image={seoImage}
      url={url}
    />

    <section className={styles.page}>
      <div className={styles.content}>
        <h1 style={{ opacity: 0, margin: 0 }}>{seoTitle}</h1>
        <ReactMarkdown source={text} />
      </div>
    </section>
  </SimpleLayout>
)

export default TermsPageComponent
